
import {Vue, Component, Prop, Ref} from 'vue-property-decorator';
import bloks from "@/pages/manage/booklet-templates/components/bloks.json";
import BlockController from "@/pages/manage/booklet-templates/components/block-controller.vue";
import HtmlBlockEditor from "@/pages/manage/booklet-templates/components/editors/html-block-editor.vue";

@Component({
  components: {HtmlBlockEditor, BlockController}
})
export default class block extends Vue {
  @Ref("htmlEditor") htmlEditor!:HtmlBlockEditor
  @Prop() block!:any
  $message:any
  get caption(){
    return  bloks.find(b => b.name == this.block.name)?.caption
  }

  async edit() {
    await this.htmlEditor.edit(this.block);
    this.$message("Данные успешно обновлены");
  }
}
