
import {
  mdiArrowAll,
  mdiCog,
  mdiPen,
  mdiPlus,
  mdiStar,
  mdiTrashCan,
  mdiUpload,
} from "@mdi/js";
import { Vue, Component, Ref, Prop } from "vue-property-decorator";
import ImageEditor from "@/components/image-editor.vue";
import DataEditor from "@/components/helpers/data/editor-dialog.vue";
import { ListDataSource } from "@/data/List/ListDataSource";
import draggable from "vuedraggable";
import BookletTemplateGalleryImage
  from "@/pages/manage/booklet-templates/components/booklet-template-gallery-image.vue";
@Component({
  components: {BookletTemplateGalleryImage, ImageEditor, DataEditor, draggable },
})
export default class ResourceInlineEditor extends Vue {
  @Prop() block!:any
  @Ref("imageEditor") imageEditor!: ImageEditor;
  @Ref("imageConfigurator") imageConfigurator!: DataEditor;
  resolve!:Function;
  $confirm: any;
  $message: any;
  dialog = false;
  loaded: boolean = false;

  imageDataSource: ListDataSource = new ListDataSource({
    className: "BookletTemplateBlockImage",
    items:this.block.images.sort((p:any,n:any) => p.priority - n.priority),
    config: {
      pageSize: 1000,
      orderFieldName: "Priority",
      orderFieldDirection: "ASC",
    },
  });

  getCol = (image:any) => image.grid ?? undefined;

  icons: any = {
    plus: mdiPlus,
    arrowAll: mdiArrowAll,
    cog: mdiCog,
    delete: mdiTrashCan,
    pen: mdiPen,
    upload: mdiUpload,
    star: mdiStar,
  };

  public open(){
    this.dialog = true;
    return new Promise(r => this.resolve = r);
  }

  public close(){
    this.dialog = false;
    this.resolve();
  }

  async add({ file }: any) {
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async ({ target }: any) => {
      try {

        await this.imageDataSource.add({
          name: file.name,
          size: file.size,
          base64: target.result?.toString().split(",")[1],
          bookletTemplateBlockId: this.block.id,
        });
        this.$message("Изображение успешно добавлен");
      } catch (error) {
        this.$message(error, "error");
      }
    };
  }
}
