
import {Vue, Component, Prop, Ref} from 'vue-property-decorator';
import bloks from "@/pages/manage/booklet-templates/components/bloks.json";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
import BlockController from "@/pages/manage/booklet-templates/components/block-controller.vue";
import BookletTemplateGalleryEditor from "@/pages/manage/booklet-templates/components/booklet-template-gallery-editor.vue";
import BookletTemplateGalleryImage
  from "@/pages/manage/booklet-templates/components/booklet-template-gallery-image.vue";
import DataEditor from "@/components/helpers/data/editor-dialog.vue";
import ImageEditor from "@/components/image-editor.vue";

@Component({
  components: {
    ImageEditor,
    DataEditor,
    BookletTemplateGalleryImage,
    BookletTemplateGalleryEditor,
    BlockController
  }
})
export default class block extends Vue {
  @Prop() block!:any
  @Ref("gallery") gallery!:BookletTemplateGalleryEditor;
  @Ref("fileRef") readonly fileRef!: HTMLInputElement;
  @Prop({ default: 1 }) maxFiles!: number;

  $message: any;
  loading:number = 0;
  getCol = (image:any) => image.grid ?? undefined;
  getStyles = (image:any) => {
    const { width } = image;
    const { height } = image;
    let size = "";
    if(width) size += `width:${width};`
    if(height) size += `height:${height};`
    if(width && height) size += "object-fit:cover; object-position:center;"
    return size;
  }

  dataSource:ObjectDataSource = new ObjectDataSource({
    className:"BookletTemplateGalleryBlock",
    id: this.block.id,
    model:this.block,
    config:{}
  })

  async edit(){
    await this.gallery.open();
  }
  get caption(){
    return bloks.find(b => b.name == this.block.name)?.caption
  }
}
