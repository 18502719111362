
import {Vue, Component, Prop, Ref} from 'vue-property-decorator';
import { mdiArrowAll, mdiCog, mdiTrashCan} from "@mdi/js";
import DataEditor from "@/components/helpers/data/editor-dialog.vue";
import {required} from "@/cms-services/consts";
import {ListDataSource} from "@/data/List/ListDataSource";

@Component({
  components: {DataEditor}
})
export default class BookletPage extends Vue {
  @Prop() dataSource!:ListDataSource
  @Prop() item:any
  @Prop() isActive!:boolean
  @Ref("pageEditor") pageEditor!:DataEditor

  $message:any
  icons: any = {
    arrowAll: mdiArrowAll,
    cog: mdiCog,
    delete: mdiTrashCan,
  };

  pageFields: any = [
    {
      editor: "string",
      attrs: {
        label: "Заголовок",
        class: "mt-5",
      },
      name: "caption",
      validations: [required()],
    },
    {
      editor: "number",
      attrs: {
        label: "Высота",
      },
      name: "width",
      grid:{cols:6},
      validations: [required()],
    },
    {
      editor: "number",
      attrs: {
        label: "Ширина",
      },
      name: "height",
      grid:{cols:6},
      validations: [required()],
    },
    {
      editor: "memo",
      attrs: {
        label: "Описание",
      },
      name: "description",
    },
  ];
  pageSizes = [
    {
      id:1,
      caption:"A3",
      width:"3508",
      height:"4961"
    },
    {
      id:2,
      caption:"A4",
      width:"2480",
      height:"3508"
    },
    {
      id:3,
      caption:"A5",
      width:"1748",
      height:"2480"
    },
    {
      id:4,
      caption:"A6",
      width:"1240",
      height:"1748"
    },
  ]

  get pageSate() {
    return this.isActive
      ? { alertColor: "success", textColor: "white--text", iconsColor: "white" }
      : { alertColor: "default", textColor: "", iconsColor: "" };
  }

  async update(event:PointerEvent){
    event.stopPropagation();
    const data = await this.pageEditor.update(this.item);
    if(!data) return;
    await this.dataSource.update(data);
    this.$message("Данные успещно обновлены")
  }
}
