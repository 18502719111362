
import {mdiArrowAll, mdiCog, mdiPen, mdiPlus, mdiStar, mdiTrashCan, mdiUpload,} from "@mdi/js";
import {Component, Prop, Ref, Vue} from "vue-property-decorator";
import ImageEditor from "@/components/image-editor.vue";
import DataEditor from "@/components/helpers/data/editor-dialog.vue";
import {ListDataSource} from "@/data/List/ListDataSource";
import draggable from "vuedraggable";
import BookletTemplateGalleryImage
  from "@/pages/manage/booklet-templates/components/booklet-template-gallery-image.vue";
import UiHtmlEditor from "@/components/helpers/ui-html-editor.vue";
import {required} from "@/cms-services/consts";
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";

@Component({
  components: {UiHtmlEditor, BookletTemplateGalleryImage, ImageEditor, DataEditor, draggable },
})
export default class ResourceInlineEditor extends Vue {
  @Prop() block!:any
  @Ref("imageEditor") imageEditor!: ImageEditor;
  @Ref("imageConfigurator") imageConfigurator!: DataEditor;
  resolve!:Function;
  $confirm: any;
  $message: any;
  dialog = false;
  loaded: boolean = false;

  icons: any = {
    plus: mdiPlus,
    arrowAll: mdiArrowAll,
    cog: mdiCog,
    delete: mdiTrashCan,
    pen: mdiPen,
    upload: mdiUpload,
    star: mdiStar,
  };

  dataSource: ListDataSource = new ListDataSource({
    className: "BookletTemplateBlockImage",
    items:[this.block.image],
    config:{}
  });

  objectDataSource: ObjectDataSource = new ObjectDataSource({
    className: "BookletTemplateBlockImage",
    config:{},
    id:this.block?.imageId
  });

  blockDataSource:ObjectDataSource = new ObjectDataSource({
    className:"BookletTemplateHtmlImageBlock",
    config:{},
    id:this.block.id,
    model:this.block
  })

  fields: any = [
    {
      editor: "string",
      attrs: {
        label: "Имя файла",
        class: "mt-5",
      },
      name: "image.name",
      validations: [required()],
      grid:{cols:6}
    },
    {
      editor: "string",
      attrs: {
        label: "Системное имя",
        class: "mt-5",
      },
      name: "image.systemName",
      grid:{cols:6}
    },
    {
      editor: "string",
      attrs: {
        label: "Ширина",
      },
      name: "image.width",
      grid:{cols:6}
    },
    {
      editor: "string",
      attrs: {
        label: "Высота",
      },
      name: "image.height",
      grid:{cols:6}
    },
    {
      editor: "string",
      name: "image.grid",
      attrs: {
        label: "Колонки",
      },
    },
    {
      editor: "enum",
      name:"image.position",
      attrs:{
        label: "Вертикальное выравнивание",
      },
      config:{
        getItems:"getPositions",
        labelKeyName:"caption",
        valueKeyName:"value",
        type:"select",
        attrs:{
          xSmall:true
        }
      }
    }
  ];
  actions:any = {
    getPositions:()=>[
      {
        caption:"Сверху",
        value:"flex-start"
      },
      {
        caption:"По центру",
        value:"center"
      },
      {
        caption:"Снизу",
        value:"flex-end"
      }
    ]
  }

  async deleteImage(){
    await this.blockDataSource.updateField({
      fieldName:"imageId",
      fieldValue:null
    })
    await this.dataSource.remove(this.block.image.id);
    this.block.image = null;
  }

  async updateHtml(){
    await this.blockDataSource.updateField({
      fieldName:"html",
      fieldValue:this.block.html
    })
  }

  get styles(){
    const width =  this.block.image?.width;
    const height =  this.block.image?.height;
    let size = "";
    if(width) size += `width:${width};`
    if(height) size += `height:${height};`
    if(width && height) size += "object-fit:cover; object-position:center;"
    return size;
  }

  public open(){
    this.dialog = true;
    this.objectDataSource.model = this.block.image;
    return new Promise(r => this.resolve = r);
  }

  async updateImage(field:any){
    const [image,name] = field.name.split(".");
    if(image != 'image') return;
    await this.objectDataSource.updateField({
      fieldName:name,
      fieldValue:this.block.image[name]
    })
  }

  public close(){
    this.dialog = false;
    this.resolve();
  }

  public async uploadFile({ file }: any){
    const reader = new FileReader();
    reader.readAsDataURL(file);
    reader.onload = async ({ target }: any) => {
      try {
        this.block.image = await this.dataSource.add({
          name: file.name,
          size: file.size,
          base64: target.result?.toString().split(",")[1],
          bookletTemplateBlockId: this.block.id,
        })
        this.block.imageId = this.block.image.id;
        await this.blockDataSource.update();
        this.$message("Изображение успешно добавлен");
      } catch (error) {
        this.$message(error, "error");
      }
    };
  }


}
