
import {Component, Prop, Vue} from 'vue-property-decorator';
import {ObjectDataSource} from "@/data/Object/ObjecDatatSource";
import {ListDataSource} from "@/data/List/ListDataSource";
import {mdiPlus} from "@mdi/js";
import bloks from "./bloks.json"
import BookletPage from "./booklet-page.vue";
import BookletBloks from "./booklet-bloks.vue";
import draggable from "vuedraggable";

@Component({
  components: {
    BookletBloks,
    BookletPage,
    draggable
  }
})
export default class BookletEditor extends Vue {
  @Prop() dataSource!:ObjectDataSource
  blocks = bloks
  $message:any
  icons: any = {plus: mdiPlus};

  pageDataSource:ListDataSource = new ListDataSource({
    className:"bookletTemplatePage",
    config:{},
    items: this.dataSource.model.pages.sort(
      (p: any, n: any) => p.priority - n.priority
    )
  });
  selectedPageId:number|null =  this.dataSource.model?.pages?.[0]?.id;

  async add(event:PointerEvent){
    event.stopPropagation();
    await this.pageDataSource.add({
      bookletId:this.dataSource.model.id,
      caption:"Страница " + this.pageDataSource.items.length,
      width:"848px",
      height:"1200px"
    }, true)
  }

  async remove(event:PointerEvent,id:number){
    event.stopPropagation();
    await this.pageDataSource.remove(id)
    this.$message("Страница успешно удалено");
  }
}
