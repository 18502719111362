
import { Vue, Component, Prop, Ref } from "vue-property-decorator";
import { required } from "@/cms-services/consts";
import DataEditor from "@/components/helpers/data/editor-dialog.vue";
import {
  mdiArrowAll,
  mdiCog,
  mdiPen,
  mdiPlus,
  mdiStar,
  mdiTrashCan,
  mdiUpload,
} from "@mdi/js";
import ImageEditor from "@/components/image-editor.vue";
import { ListDataSource } from "@/data/List/ListDataSource";

@Component({
  components: { ImageEditor, DataEditor },
})
export default class bookletTemplateGalleryImage extends Vue {
  @Ref("imageEditor") imageEditor!: ImageEditor;
  @Ref("imageConfigurator") imageConfigurator!: DataEditor;
  @Prop() dataSource!: ListDataSource;
  @Prop() image!: any;
  $message: any;
  $confirm: any;
  icons: any = {
    plus: mdiPlus,
    arrowAll: mdiArrowAll,
    cog: mdiCog,
    delete: mdiTrashCan,
    pen: mdiPen,
    upload: mdiUpload,
    star: mdiStar,
  };
  fields: any = [
    {
      editor: "string",
      attrs: {
        label: "Имя файла",
        class: "mt-5",
      },
      name: "name",
      validations: [required()],
      grid: { cols: 6 },
    },
    {
      editor: "string",
      attrs: {
        label: "Системное имя",
        class: "mt-5",
      },
      name: "systemName",
      grid: { cols: 6 },
    },
    {
      editor: "string",
      attrs: {
        label: "Ширина",
      },
      name: "width",
      grid: { cols: 6 },
    },
    {
      editor: "string",
      attrs: {
        label: "Высота",
      },
      name: "height",
      grid: { cols: 6 },
    },
    {
      editor: "string",
      name: "grid",
      attrs: {
        label: "Колонки",
      },
    },
    {
      editor: "enum",
      name: "position",
      attrs: {
        label: "Вертикальное выравнивание",
      },
      config: {
        getItems: "getPositions",
        labelKeyName: "caption",
        valueKeyName: "value",
        type: "select",
        attrs: {
          xSmall: true,
        },
      },
    },
  ];
  actions: any = {
    getPositions: () => [
      {
        caption: "Сверху",
        value: "flex-start",
      },
      {
        caption: "По центру",
        value: "center",
      },
      {
        caption: "Снизу",
        value: "flex-end",
      },
    ],
  };

  get styles() {
    const width = this.image?.width;
    const height = this.image?.height;
    let size = "";
    if (width) size += `width:${width};`;
    if (height) size += `height:${height};`;
    if (width && height) size += "object-fit:cover; object-position:center;";
    return size;
  }

  async update(image: any) {
    const model: any = await this.imageConfigurator.update(image);
    if (!model) return;
    Object.assign(image, model);
    await this.dataSource.updateField(model.id, [
      {
        fieldName: "name",
        fieldValue: model.name,
      },
      {
        fieldName: "systemName",
        fieldValue: model.systemName,
      },
      {
        fieldName: "width",
        fieldValue: model.width,
      },
      {
        fieldName: "height",
        fieldValue: model.height,
      },
      {
        fieldName: "grid",
        fieldValue: model.grid,
      },
      {
        fieldName: "position",
        fieldValue: model.position,
      },
    ]);
    this.$message("Успешно обновлено");
  }

  async edit(image: any): Promise<any> {
    const src = `/api/v1/objectimage/p${image.id}.jpg`;
    const base64 = await this.imageEditor.edit(src);
    if (!base64) return;
    image.base64 = base64?.toString().split(",")[1];
    try {
      await this.dataSource.update(image);
      this.$message("Изображение успешно изменено");
    } catch (error) {
      this.$message(error, "error");
    }
  }

  async remove(image: any) {
    const confirm: any = await this.$confirm(
      "Вы действительно хотите удалить данную картинку?"
    );
    if (!confirm) return;
    try {
      await this.dataSource.remove(image.id);
      this.$message("Изображение успешно удалено");
    } catch (error) {
      this.$message(error, "error");
    }
  }
}
